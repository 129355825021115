/* In your main CSS file or component CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

.outfit {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  color: #02273A;
  font-weight: 500;
  font-size: 17px;
  /* font-style: normal; */
}

.pad {
  padding: 3vh 10vh;
}


.homeHeadingDiv{
  flex: 0.5;
}

.mainDiv{
  display: flex;
  flex-direction: row;
  align-items: center;

}

.oo{
  display: none;
}

@media (max-width:700px) {
  .homeHeadingDiv{
    flex: 1;
  }
  .mainDiv{
    flex-direction: column;
  }
  .oo{
    display: block;
  }
}


@media (max-width:700px) {
  .pad{
    padding: 1vh 5vh;
  }
}

@media (max-width:425px) {
  .pad{
    padding: 1vh 3vh;
  }
}
.margin{
  margin: 10px 10px;
}

/* .App{
  margin: 25px;
} */
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.col {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
}

.rowCaseStudy {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paddingL {
  padding-left: 1.5vh;
}

.font16 {
  font-size: 16px;
}

.paddingR {
  padding-right: 2.5vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.centerIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.justify-btw {
  display: flex;
  justify-content: space-between;
}

.font20 {
  font-size: 20px;
}

.font34 {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 5vh;
}

.font50 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 5vh;
}

.width {
  width: 100%;
  justify-content: space-around;
  font-size: 20px;
  /* width: 826px; */
}

.imageMan{
  display: block;
}

/* star image container  */


.background-container {
  /* position: relative; */
  width: 99vw;
  height: 424px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.centered-text {
  color: #02273A; /* Adjust text color for better visibility */
  z-index: 1; /* Ensure the text is above the image */
}

.centered-text h1 {
  font-size: 3rem; /* Adjust font size as needed */
  margin: 0;
}

.centered-text p {
  font-size: 1.5rem; /* Adjust font size as needed */
  margin: 0;
}

@media (max-width: 700px) {
  .width{
    font-size: 15px;
  }

  .imageMan{
    display: none;
  }
  }


  @media (max-width: 500px) {
    .center{
      flex-direction: column;
    }
    .centerIcon{

    }
    /* .imageMan{
      display: block;
    } */
  }

.div2 {
  background-color: #F8F8F8;
  display: flex;
}

@media (max-width:700) {
  .div2{
    width: 100%;
  }
}

.btn-blue {
  display: inline-block;
  white-space: nowrap;
  background-color: #02273A;
  color: white;
  /* width: 182px;
  height: 54px; */
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  padding: 2.4vh 5.5vh;
  cursor: pointer;

}

.meetOurLaw{
  display: inline-block;
  white-space: nowrap;
  background-color: #02273A;
  color: white;
  /* width: 182px;
  height: 54px; */
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  padding: 2.4vh 5.5vh;
}

.icon {
  font-size: 2rem; /* Default size for larger screens */
}

@media (max-width: 1050px) {
  .icon {
    font-size: 1.5rem; /* Smaller size for smaller screens */
  }
}

.subHeading {
  font-size: 20px;
  font-weight: 500;
  color: #E05A63;
  text-transform: uppercase;
}

.heading {
  font-weight: 700;
  font-size: 65px;
  margin-bottom: 2vh;
}

.box {
  width: 68vh;
  /* height: 45vh; */
  height: auto;
  /* Ensure it grows with content */

}

.paddingB {
  padding-bottom: 5vh;
}

.center2 {
  display: flex;
  align-self: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.gray-txt {
  color: #AFAFAF;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 2vh;
}

.redStar {
  position: relative;
  top: 150px;
  right: 110px;
}

.blueStar {
  position: relative;
  top: 400px;
  right: 70px;
  /* top: 200px; */
  /* left: 50px; */
}

.blueStar2 {
  position: relative;
  bottom: 200px;
  /* right: 20px; */
  /* top: 200px; */
  left: 650px;
}


.hieght200{
  height: 200px;
}

@media (max-width:700px) {
  .hieght200{
    height: 10px;
  }
}
@media (max-width:1700px) {
  .blueStar2 {
    display: none;
    /* position: relative;
    bottom: 200px;
    left: 400px; */
  }
}

.underline {
  text-decoration: underline;
  font-weight: 300;
}

.bannertext {
  color: white;
  font-size: 50px;
  font-weight: 600;
}

@media (max-width:1050px) {
  .bannertext{
    font-size: 30px;
  }
}

.aboutusMainDiv {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.aboutUs{
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

@media (max-width:1300px) {
  .aboutUs{
    flex-direction: column;
    justify-content: center;
  }
}


@media (max-width: 1050px) {
  .aboutusMainDiv {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    align-items: center;
    justify-content: center;
  }

  .headingAboutus {
    font-size: 25px;
  }
}

.headingAboutus {
  font-size: 50px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 5vh;
  margin-top: 0;
}

.textbold {
  font-size: 50px;
  font-weight: 700;
  margin: 52px;
}

.textbold2 {
  font-size: 32px;
  font-weight: 500;
  margin: 0;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.responsive-img {
  /* width: 100%; */
  max-width: 100%;
  height: auto;
}

.new {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-left: 5vh;
}

.new2 {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border-radius: 10px;
  padding: 0px 10px;
}


@media (max-width:500px) {
  .new2{
    padding: 40px 10px;
  }
  .bannertext{
    font-size: 20px;
  }
}

.new3 {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  /* margin-top: 3vh; */
  padding: 0px 10px;
}

.padding {
  padding: 0px 52px 10px 52px;
}

.paddingtext {
  padding: 0px 50px 50px 50px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .new {
    padding-left: 2vh;
  }

  .textbold {
    font-size: 35px;
    margin: 20px;
  }

  .textbold2 {
    font-size: 24px;
  }

  .padding {
    padding: 0px 20px 10px 20px;
  }

  .paddingtext {
    padding: 0px 20px 20px 20px;
  }

  .new3 {
    /* margin-bottom: 5vh; */
  }
}

@media (max-width: 480px) {
  .textbold {
    font-size: 25px;
    margin: 10px;
  }

  .textbold2 {
    font-size: 18px;
  }

  .padding {
    padding: 0px 10px 10px 10px;
  }

  .paddingtext {
    padding: 0px 10px 10px 10px;
  }

  .new3 {
    /* margin-bottom: 6vh; */
  }
}


.whoWeAre {
  color: #F9F9F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vh;
}

.weAreHeading {
  padding: 5vh;
  font-size: 50px;
  font-weight: 500;
}

.whoWeAreText {
  font-size: 19px;
  padding: 20px 195px;
  font-weight: 400;
  text-align: center;
}

.card1 {
  padding: 45px;
  background-color: #F9F9F9;
  border-radius: 5px;
  margin-bottom: 20px;
}

.circle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.imageCenter {
  color: purple;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.readmore {
  padding-top: 3vh;
  padding-right: 9vh;
  align-items: center;
  display: flex;
  align-self: end;
  justify-content: end;
  color: #fff;
}

.caseStudy {
  padding: 2vh;
  background-color: #F9F9F9;
}

.caseStudyTitle {
  margin: 2vh 0;
  font-size: 23px;
  font-weight: 600;
}

.cStudyText {
  font-size: 17px;
  color: #8F8F8F;
  font-weight: 400;
}

.more {
  padding: 2vh 0vh;
  font-size: 18px;
  color: #E05A63;
  font-weight: 700;
  cursor: pointer;
  margin-right: 1.5vh;
}

.btnEnd {
  align-items: end;
  display: flex;
  align-self: end;
  justify-content: end;
}

.bottomMargin {
  margin-bottom: 2vh;
}

.featureServiceRight {
  display: flex;
  align-items: center;
}

.featureServiceMain {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.service {
  margin-bottom: 60px;
  cursor: pointer;
}
.selectedService {
  color: #fff; /* Change this color to your desired selected text color */
}
.serviceId {
  /* writing-mode: vertical-rl; */
  /* transform: rotate(0deg); */
  margin-right: 10px;
  color: #8c8c8c; /* Default color for unselected IDs */
}

.serviceTitle{
  margin-right: 10px;
  color: #8c8c8c; 
}

.selectedService {
  color: #fff; /* Change this color to your desired selected ID color */
}

/* .serviceTitle{
  color: #fff;
} */

.serviceDescription {
  margin-top: 5px;
  margin-bottom: 0px;
  color: #fff;
}


.firstDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.servicesHeading{
  display: flex;
  font-size: 34px;
  font-weight: 500;
}

@media (max-width:800px) {
 .firstDiv{ display: none;
}
  .navbarLine{
    display: none;
  }
}


.praticeAreaMain {
  display: flex;
  flex-direction: row;
  align-items: center;
}


@media (max-width: 1000px) {
  .praticeAreaMain {
    flex-direction: row;
    overflow-x: scroll;
  }

  .card1 {
    flex: none;
    width: 80%;
    margin-right: 20px;
  }
}

@media (max-width: 1250px) {
  .responsive-img {
    width: 80%;
    /* Reduce width when screen is less than 1250px */
  }

  .subHeading {
    font-size: 18px;
  }

  .heading {
    font-size: 55px;
  }

  .headingAboutus {
    font-size: 55px;
  }

  .caseStudyTitle {
    font-size: 22px;
  }

  .cStudyText {
    font-size: 14px;
  }

  .more {
    font-size: 12px;
  }

  .whoWeAreText {
    font-size: 12px;
  }

  .weAreHeading {
    font-size: 35px;
  }

  .font20 {
    font-size: 12px;
  }

  .font50 {
    font-size: 35px;
  }

  .font34 {
    font-size: 25px;
  }

  .featureServiceRight {
    flex-direction: column;
  }
  .servicesHeading{
    font-size: 24px;
    font-weight: 500;
  }

}

@media (max-width:1070px) {
  .featureServiceMain {
    flex-direction: column;
  }
}

@media (max-width: 1000px) {
  .responsive-img {
    width: 60%;
    /* Further reduce width when screen is less than 1000px */
  }

  .subHeading {
    font-size: 16px;
  }

  .heading {
    font-size: 45px;
  }

  .headingAboutus {
    font-size: 45px;
  }

  .subHeading {
    font-size: 16px;
  }

  .caseStudyTitle {
    font-size: 20px;
  }

  .cStudyText {
    font-size: 14px;
  }

  .more {
    font-size: 12px;
  }

  .whoWeAreText {
    font-size: 10px;
    padding: 10px 15px;
  }

  .weAreHeading {
    font-size: 25px;
  }

  .font20 {
    font-size: 8px;
  }

  .font50 {
    font-size: 25px;
  }

  .font34 {
    font-size: 15px;
  }

 
}

@media (max-width: 900px) {
  .praticeAreaMain {
    flex-direction: column;
  }
}



/* ................................. */
.buttonnext{
  background-color: red;
  opacity: 0.4;
}
.slider-container {
  overflow: hidden;
  /* width: 80%; */
  margin: 0 auto;
  text-align: center;
}

.testimonial {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.testimonial-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 100%;
  max-width: 350px;
  /* Adjust as needed */
  margin: auto;
  /* Centering the card */
}

.testimonial-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.testimonial-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.stars {
  color: #ffc107;
}

.date {
  color: #999;
}

/* ......... */


.subscribeBox {
  margin: 20px 244px;
  background-color: #02273A;
  color: #fff;
  padding: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  /* max-width: 1432px; */

}


.subText {
  font-size: 35px;
  font-weight: 400;
}

.subscribe-container {
  display: flex;
  /* border: 1px solid #fff; */
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  width: 680px;

}

.subscribe-input {
  padding: 30px;
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
}

.subscribe-input::placeholder {
  color: #cfd8dc;
}

.subscribe-button {
  padding: 10px 20px;
  background-color: #0d3a4b;
  color: #fff;
  /* border: 5px solid white; */
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
  border-radius: 5px;
  width: 244px;
}

.subscribe-button:hover {
  background-color: #0b3140;
}

@media (max-width: 1250px) {
  .subscribeBox {
    flex-direction: column;
  }

  .subscribe-container {
  width: auto;
  }

  .subscribe-button {
    width: auto;
}
.subscribeBox{
  /* width: 100%; */
  margin:0
}
.subText{
  font-size: 25px;
  white-space: nowrap;
}
}

@media (max-width:430px) {
  .subscribeBox {
    padding: 20px 100px;

}
}

.maxW {
  width: 628px;
  display: flex;
  justify-content: space-between;
}

.navbarLine{
  width: 92vw;
  /* padding: 0 30vh; */
  border: 2px solid #EDEDED;
  align-self: center;
  align-items: center;
  /* opacity: 20%; */
}

.line {
  width: 80vw;
  /* padding: 0 30vh; */
  border: 1px solid #02273A;
  align-self: center;
  align-items: center;
  opacity: 20%;
}

.blueBackground {
  background-color: #02273A;
  border-radius: 5px;
}


.whiteText {
  color: white;
}

/* .font34{
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 5vh;
} */

/* .font50{
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 5vh;
} */

.paddingBottom {
  padding-bottom: 5vh;
}

.txt {
  width: 100%;
  /* Full width for mobile devices */
  text-align: center;
  /* Center text for mobile view */
  margin-bottom: 20px;
}

.redBtn {
  width: 100%;
  /* Full width for mobile devices */
  height: auto;
  padding: 1vh 3vh;
  display: inline-block;
  background-color: #E05A63;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  /* Ensure text is readable on mobile devices */
  text-align: center;
  cursor: pointer;
}


/* navbarrrrrrrrrrrrrrrrrr */
.featureServiceMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 20px; */
  position: relative;
}

/* .navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
@media (max-width: 750px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* other styles specific to mobile */
  }
}

.nav-menu {
  white-space: nowrap;
  display: flex;
  gap: 70px;
  align-items: center;
  padding-bottom: 2vh;
}

.nav-item {
  padding: 10px 15px;
  cursor: pointer;
  position: relative;
}

.nav-item.selected {
  color: #FF3366;
}

.nav-item.selected::after {
  content: '•';
  color: #FF3366;
  font-size: 24px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.center {
  display: flex;
  align-items: center;
}

.hamburger {
  display: none;
  cursor: pointer;
  z-index: 101; /* Ensure it's on top of the menu */

}

.icon {
  margin-right: 10px;
}

/* .btn-blue {
  background-color: #02273A;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
} */

.paddingL {
  padding-left: 10px;
}

.book1{
  display: none;
}

@media (max-width:1300px) {
  .nav-menu {
  gap:20px
  }
}


@media (max-width: 750px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 100; /* Ensure the menu is on top */
  }

  .nav-menu.open {
    display: flex;
  }

  .hamburger {
    display: block;
    align-items: start;
  }

  .book1{
    display: flex;
    align-items: center;

  }

  .book2{
    display: none;
  }
}


/* review */

.swiper-slide {
  /* overflow: hidden; */
  margin: 0;
  width: auto;
}

.ss {
  margin: 0px;
}

.sws {
  padding: 20px 0px;
  overflow: hidden;


}

.slider {
  overflow: hidden !important;
}

.slick-track {
  /* overflow: hidden; */
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.slick-slide {
  width: 550px !important;
}

.buttonForward {
  background-color: #02273A;
  padding: 16px 21px;
  border-radius: 5px;
  border: none;
  margin-left: 2vh;
  cursor: pointer;
}

.review-slider-container {
  width: 100%;
  padding: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.review-card {
  background: #f9f9f9;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 38px;
  text-align: left;
  /* width: 478px; */
  /* height: 245px; */
}

.review-image {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.review-card h3 {
  font-size: 18px;
  margin: 10px 0;
}

.review-card p {
  font-size: 14px;
  color: #555;
}

.review-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.review-rating span {
  color: #FCA311;
  /* Star color */

}

.star {
  font-size: 24px; /* Adjust the size as needed */
  color: #FCA311; /* Gold color for stars */
}
.star.filled {
  color: #FCA311; /* Gold color for filled stars */
}

.review-date {
  font-size: 16px;
  font-weight: 400;
  color: #E05A63;
}

.swiper-button-next,
.swiper-button-prev {
  color: #000;
  /* Navigation button color */
}

.swiper-pagination-bullet {
  background: #000;
  /* Pagination bullet color */

}

/* .quotes { */
  /* width: 30px;
  height: 30px; */
/* } */


/* .....................OUR TEAM PAGE................ */

/* .colJust{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
} */

.num{
  white-space:nowrap;
  margin: 0;
}

.stats-container {
  padding-top: 5vh;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.stat-item {
  width: 300px;
  padding: 35px 10px;
  border-radius: 8px;
  text-align: center;
  background-color: #f1f1f1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.stat-item.selected {
  background-color: #02273A;
  color: white;
}

.stat-item h1 {
  margin: 0;
  font-size: 2em;
}

.stat-item p {
  margin: 10px 0 0;
  font-size: 1.2em;
}



/*............... part 2.......................... */
.font30{
  font-size: 30px;
  font-weight: 500;
}

@media (max-width:600px) {
  .font30{
    font-size: 20px;
  }
}

.p{
  padding: 40px 0;
}

.services-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  
}

.service-item {
  height: 195px;
  display: flex;
  align-items: center;
  padding:  0 20px;
  border-radius: 8px;
  background-color: #f1f1f1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-item.selected {
  background-color: #02273A;
  color: white;
}

.icon {
  font-size: 2em;
  margin-right: 20px;
}

.content {
  flex: 1;
}

.service-item h2 {
  margin: 0;
  font-size: 30px;
}


.service-item p {
  margin: 10px 0 0;
  font-size: 1em;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #02273A;
  margin-right: 15px;
}

.icon-container.selected {
  background-color: white;
}

.icon-container img {
  width: 40px;
  height: 40px;
}

.link{
  text-decoration: none;
  color: #02273A;
}
@media (max-width:600px) {
  .service-item h2{
    font-size: 17px;
  }

  .service-item p {
    font-size: 13px;
  }
  .icon-container{
    width: 70px;
    height: 70px;
  }
}
@media ( max-width:500px ){
  .subscribe-container{
    width: 300px;
    flex-direction: column;
  }
  .subText{
    font-size: 18px;
  }
  .p{
    padding: 0px 0px;
  }
}
@media (max-width:350px) {
  .service-item h2{
    font-size: 14px;
  }

  .service-item p {
    font-size: 10px;

  }
  .icon-container{
    width: 50px;
    height: 50px;
  }
}




/* profile card..................... */
.wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.profile-card {
  width: 410px;
  /* border-radius: 8px; */
  overflow: hidden;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.image-container {
  background-color: #f7f7f7;
  width: 100%;
  /* height: 400px; */
  overflow: hidden;
  position: relative;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.imageee{
  width: 410px;
  height: 504px;
  object-fit: cover;
  border-radius: 5px;

}

.info {
  background-color: #02273A;
  color: white;
  padding: 27px 20px;
  text-align: center;
  margin: 29px;
  border-radius: 8px;
  position: relative;
  bottom: 100px;

}

.info h2 {
  margin: 0;
  /* font-size: 1.83em; */
  
}

.info p {
  margin: 5px 0 0;
  font-size: 1.0em;
  color: #d1d1d1;
}

/* ....................... */

/* contact US............ */

.responsive-iframe{
  width:708px;
  height:800px;
  position: relative;
  right: 100px;
  top: 62px;
  z-index: 1000;
}
.blue-container{
  height: 910px;
}

.inputfield{
  height: 50px;
  width: 725px;
  border: 1px solid #E0E0E0;
  padding: 5px 25px;
  outline: none;
  border-radius: 5px;
  margin-bottom: 3vh;
}

.inputfieldtextarea{
  height: 150px;
  width: 725px;
  border: 1px solid #E0E0E0;
  padding: 5px 25px;
  outline: none;
  border-radius: 5px;
  margin-bottom: 3vh;
}
.inputfieldtextarea::placeholder {
 display: flex;
 align-self: self-start;
 align-items: start;
 justify-content: start;
 
  color: #999; /* Placeholder text color */
  font-style: italic; /* Placeholder text style */
  opacity: 0.6; /* Placeholder text opacity */
  /* Additional styling properties as needed */
}
.blueBackground{
  background-color: #02273A;
  height: 110%;
  /* width: 50%; */
}


.map-container {
  background-color: #02273A; /* Blue background color */
  border-top-right-radius: 20px; /* Adjust the radius as needed */
  border-bottom-right-radius: 20px; /* Adjust the radius as needed */
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8vh 0vh;
}

.map-image {
  width: 100%;
  height: auto;
}

.inputAndMapDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10vh;
}



@media (max-width: 1800px) {
  .inputfield {
    height: 50px;
    width: 600px;
  }
  .inputfieldtextarea{
    height: 150px;
    width: 600px;
  }
  .responsive-iframe{
    width:608px;
    height:700px
  }
  .blue-container{
    height: 820px;
  }
}

@media (max-width: 1600px) {
  /* .inputfield, .inputfieldtextarea {
    width: 90%;
  } */

  .inputfield{
    height: 50px;
    width: 500px;
  }
  .inputfieldtextarea {
    height: 100px;
    width: 500px;

  }
  .responsive-iframe{
    width:508px;
    height:600px
  }
  .blue-container{
    height: 730px;
  }
}

@media (max-width:1400px) {
  .inputAndMapDiv{
  flex-direction: column;
  }
  .blue-container{
    display: none;
  }
  .inputfield{
    width: 90%;
  }
  .inputfieldtextarea{
    width: 90%;
  }
  .responsive-iframe{
    width:90%;
    height:600px;
    position: relative;
    right: 0px;
    top: 0px;
  }
}


@media (max-width: 768px) {
  .inputfield, .inputfieldtextarea {
    width: 80%;
  }
  .responsive-iframe{
    width:70%;
    height:400px;
    position: relative;
    right: 0px;
    top: 0px;
  }
}

@media (max-width:450px) {
  .responsive-iframe{
    width:100%;
    height:200px;
    position: relative;
    right: 0px;
    top: 0px;
  }
}
/*  */

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: start; /* Center align the tabs */
  flex-wrap: wrap; /* Allow wrapping */

}

.tab-button {
width: 190px; /* Make all tabs take up equal space */
  height: 45px; /* Set a fixed height */
  padding: 10px 20px;
  border: 1px solid gray;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #555;
  transition: color 0.3s ease;
}

/*  */

.tab-button.active {
  border-color: #FF3C78; /* Red border color for active state */
  color: #FF3C78;
}

.tab-button.active::after{
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px; /* Adjust the position of the underline */
  width: 50%;
  height: 3px;
  /* border: 1px solid #FF3C78; */
  /* background-color: #FF3C78; Red underline for active and hover states */
  border-radius: 3px;
}

@media (max-width:700px) {
  .tabs{
    align-items: center;
    justify-content: center;
  }
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start; /* Center align the cards */

}

.card {
  flex: 0 0 32%;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  background-color: #fff;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: 330px;
  object-fit: cover;
  border-radius: 5px;
}


.caseStudy {
  padding: 15px;
}

.subHeading {
  font-size: 18px;
  font-weight: 500;
  color: #E05A63;
  margin-bottom: 5px;
}

.caseStudyTitle {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #02273A;
}

.cStudyText {
  padding: 1vh 2vh 1vh 0vh;
  font-size: 17px;
  font-weight: 400;
  color: #8F8F8F;
  margin-bottom: 15px;
}

.more {
  color: #E05A63;
  cursor: pointer;
  font-weight: bold;
}

.date-badge {
  position: relative;
  bottom: 330px;
  /* left: 480px; */
  width: 62px;
  height: 62px;
  background-color: #02273A; /* Background color of the date badge */
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}

.date-day {
  font-size: 24px;
  margin: 0;
}

.date-month {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
}

@media (max-width: 1200px) {
  .card {
    flex: 0 0 45%; /* Two cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .card {
    flex: 0 0 100%; /* One card per row on small screens */
  }
}
/*  */


.lastSection{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
} 

@media (max-width:1000px) {
  .lastSection{
    flex-direction: column;
  }
  .maxW{
    width: 100%;
    gap:10px;
    margin-bottom: 3vh;
  }
  .fontSmall{
    font-size: 12px;
    /* white-space: nowrap; */
  }
}

@media (max-width:500px) {
  .lastSection{
    font-size: 12px;
  }
  .fontSmall{
    font-size: 8px;
  }
  /* .maxW{
    width: auto;
    gap:20px;
  } */
}


@media (min-width: 600px) {
  .row {
    flex-direction: row;
    /* Row layout for tablets and larger screens */
    align-items: flex-start;
  }

  .txt {
    width: 80%;
    /* Adjust text width for larger screens */
    text-align: left;
    /* Align text to the left for larger screens */
    margin-bottom: 20px;
  }

  .redBtn {
    width: 190px;
    /* Fixed width for larger screens */
    height: 65px;
  }
}

@media (max-width: 800px) {
  .rowCaseStudy {
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .responsive-img {
    width: 40%;
    /* Further reduce width when screen is less than 750px */
  }

  .subHeading {
    font-size: 14px;
  }

  .heading {
    font-size: 35px;
  }

  .headingAboutus {
    font-size: 35px;
  }

  .subHeading {
    font-size: 14px;
  }

  .caseStudyTitle {
    font-size: 18px;
  }

  .cStudyText {
    font-size: 12px;
  }

  .more {
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .responsive-img {
    display: none;
    /* Further reduce width when screen is less than 750px */
  }

  .subHeading {
    font-size: 12px;
  }

  .heading {
    font-size: 28px;
  }

  .headingAboutus {
    font-size: 28px;
  }

  .subHeading {
    font-size: 12px;
  }

  .caseStudyTitle {
    font-size: 16px;
  }

  .cStudyText {
    font-size: 10px;
  }

  .more {
    font-size: 8px;
  }
}

@media (max-width: 375px) {
  .headingAboutus {
    font-size: 24px;
  }

  .subHeading {
    font-size: 10px;
  }

  .caseStudyTitle {
    font-size: 14px;
  }

  .cStudyText {
    font-size: 9px;
  }

  .more {
    font-size: 7px;
  }
}

.display1000{
  display: none;
}

/* .none{ */
  /* display: block; */
/* } */

@media (max-width:1000px) {
  .display1000{
    display: block;
  }

  .none{
    display: none;
  }
}

.logodetail{
  padding-left: 2vh;
  font-size: 13px;
  font-weight: 600;
  gap: 10px;
}

.colIcon{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width:800px) {
  .colIcon{
    flex-direction: column;
    align-self: start;
    align-items: start;
  }
}